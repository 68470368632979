<!-- eslint-disable vue/no-parsing-error -->
<template>
  <!-- 订单管理 -->
  <div class="product-audit-box">
    <!-- 搜索框开始 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <template>
        <el-form-item
          label="所属企业"
          prop="cmpCode"
        >
          <el-select
            v-model="formInline.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 搜索框结束 -->
    <!-- 列表开始 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
      >
        <template #expireDate="{ row }">
          <div :class="Date.parse(new Date(row.expireDate)) < Date.parse(new Date()) ? 'expire-date-status' : ''">
            {{ row.expireDate }}
            <span>{{ Date.parse(new Date(row.expireDate)) < Date.parse(new Date()) ? '已到期' : '' }}</span>
          </div>
        </template>
        <template #orderConnectStatus="{ row }">
          <span :class="row.orderConnectStatus === 1 ? 'red' : ''">{{ row.orderConnectStatus === 1 ? `待关联 截止(${row.endTime || ''})` : row.orderConnectStatus === 2 ? '已关联' : '' }}</span>
          <p />
        </template>
        <template #billOrderStatus="{ row }">
          <span :class="row.billOrderStatus === 3 ? 'blue' : row.billOrderStatus === 4 ? 'grey' : row.billOrderStatus === 6 ? 'green': 'orange'">
            {{ getDictName($store.getters.getDictionaryItem("BILL_ORDER_STATUS"), row.billOrderStatus) }}
          </span>
        </template>
      </Table>
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 列表结束 -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Pagination from '@/components/Pagination2.vue'
import Table from '@/components/Table.vue'
import { orderListPage, channelListPage } from '@/api/piaoju.js'
import { getCmpBaseinfoList } from '@/api/memberManagement'
import { getDate, getDictName } from '@/utils/util.js'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      getDate,
      getDictName,
      cmpNameArr: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'input', label: '订单号', value: 'orderNo' },
        { type: 'input', label: '客户姓名', value: 'userName' },
        { type: 'input', label: '票据尾号', value: 'billNo' },
        {
          type: 'select',
          label: '票据类型',
          value: 'billType',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: this.$store.getters.getDictionaryItem('BILL_TYPE')
        },
        {
          type: 'picker',
          label: '到期日期',
          value: 'dateArr',
          startTimer: 'expireStartDate',
          endTimer: 'expireEndDate'
        },
        {
          type: 'select',
          label: '订单状态',
          value: 'billOrderStatus',
          pLabel: 'dictName',
          pValue: 'dictId',
          multiple: true,
          child: this.$store.getters.getDictionaryItem('BILL_ORDER_STATUS')
        },
        {
          type: 'select',
          label: '所属渠道',
          value: 'channel',
          pLabel: 'channelName',
          pValue: 'channelName',
          child: []
        },
        {
          type: 'select',
          label: '票据关联状态',
          value: 'orderConnectStatus',
          pLabel: 'label',
          pValue: 'value',
          child: [{ label: '待关联', value: 1 }, { label: '已关联', value: 2 }]
        }
      ],
      itemData: [
        { label: '订单号', prop: 'orderNo', width: 200 },
        { label: '所属企业', prop: 'cmpName', width: 140 },
        { label: '客户姓名', prop: 'userName', width: 140 },
        { label: '购买金额', prop: 'buyAmount', type: 'money', width: 160 },
        { label: '年化收益率', prop: 'annualizedRevenueRate', unit: '%', width: 120 },
        { label: '票据尾号', prop: 'billLastNo', path: '/piaoju/detailBill', pathParameter: 'billManageId', width: 120 },
        { label: '所属渠道', prop: 'channel', width: 140 },
        { label: '票据类型', prop: 'billType', width: 120, child: this.$store.getters.getDictionaryItem('BILL_TYPE') },
        { label: '起息日期', prop: 'beginDate', width: 140 },
        { label: '到期日期', prop: 'expireDate', width: 140 },
        { label: '期限(天)', prop: 'term', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 180 },
        {
          label: '票据关联状态',
          prop: 'orderConnectStatus',
          width: 260
        },
        {
          label: '状态',
          prop: 'billOrderStatus',
          width: 160,
          child: this.$store.getters.getDictionaryItem('BILL_ORDER_STATUS')
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', withParameters: '/piaoju/detailOrder', handleEvent: this.viewDetails }
      ],
      total: 0
    }
  },
  mounted() {
    if (this.$route.query.billOrderStatus) this.formInline.billOrderStatus = this.$route.query.billOrderStatus
    this.remoteMethod('')
    channelListPage({
      pageSize: 10000,
      pageNum: 1
    }, (res) => {
      this.searchFormItemArr[this.searchFormItemArr.length - 2].child = [...res.data.records]
    })
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      orderListPage(this.formInline, (res) => {
        if (res.data.records) {
          this.listData = [...res.data.records]
          this.listData.forEach((item) => {
            if (item.billNo) item.billLastNo = item.billNo.substr(-5)
          })
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 详情
    viewDetails({ id }, withParameters) {
      this.$router.push({
        path: withParameters,
        query: { id }
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sub-title {
  position: absolute;
  top: 22px;
  left: 100px;
  color: #666;
}
.view-old-material {
  color: $mainBlue;
  margin-left: 20px;
  cursor: pointer;
}
.expire-date-status {
  color: #D89283;

  span {
    margin-left: 2px;
    display: inline-block;
    background-color: #FCF5F0;
    padding: 2px 4px;
    border-radius: 2px;
  }
}
.blue {
  color: #4595E7;
  background-color: #F4F6FF;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.grey {
  color: #747474;
  background-color: #F2F2F2;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.green {
  color: #09AF42;
  background-color: #F4FCF7;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.orange {
  color: #ED9948;
  background-color: #FEF7F1;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.red {
  color: $assistColor5;
  background-color: #fdf4f4;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
</style>
